import Vue from 'vue'
import VueRouter from 'vue-router'
//import Offers from '../views/Offers.vue'
import OfferTemplates from '../views/OfferTemplates.vue'
import OfferTemplateDetail from '../views/OfferTemplateDetail.vue'
import OffersSpecs from '../views/OffersSpecs.vue'
import Specs from '../views/Specs.vue'
import Orders from '../views/Orders.vue'
import Settings from '../views/Settings.vue'
import Invoices from '../views/Invoices.vue'
import Login from '../views/auth/Login.vue'
import ForgotPassword from '../views/auth/ForgotPassword.vue'
import ResetPassword from '../views/auth/ResetPassword.vue'
import TestSpecs from '../views/TestSpecs.vue'
import Listings from '../views/Listings.vue'
import ShowListing from '../views/ShowListing.vue'
import authGuard from './guards/auth.js'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        redirect: {name: 'offerTemplates'},
        beforeEnter: authGuard.ifAuthenticated
    },
    // {
    //     path: '/offers',
    //     name: 'offers',
    //     component: Offers,
    //     beforeEnter: authGuard.ifAuthenticated
    // },
    {
        path: '/offerTemplates',
        name: 'offerTemplates',
        component: OfferTemplates,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/offerTemplateDetail/:id',
        name: 'offerTemplateDetail',
        component: OfferTemplateDetail,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/offersSpecs',
        name: 'offersSpecs',
        component: OffersSpecs,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/forgotPassword',
        name: 'forgotPassword',
        component: ForgotPassword,
        beforeEnter: authGuard.ifNotAuthenticated
    },
    {
        path: '/resetPassword/:token',
        name: 'resetPassword',
        component: ResetPassword,
        beforeEnter: authGuard.ifNotAuthenticated
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        beforeEnter: authGuard.ifNotAuthenticated
    },
    {
        path: '/orders',
        name: 'orders',
        component: Orders,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/specs',
        name: 'specs',
        component: Specs,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/testSpecs',
        name: 'testSpecs',
        component: TestSpecs,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/invoices',
        name: 'invoices',
        component: Invoices,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/listings',
        name: 'listings',
        component: Listings,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/listing/:listingPublicId',
        name: 'listing',
        component: ShowListing,
        beforeEnter: authGuard.ifAuthenticated
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
