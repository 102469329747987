<script type="text/ecmascript-6">
import MainLayout from '@/layouts/Main.vue'
import invoiceService from '@/services/invoices'

export default {
    
    components: {MainLayout},
    data() {
        return {
            invoices: [],
            currentPage: 1,
            perPage: 10,
            total: 0,
            paid: false,
            error: ''
        }
    },
    methods: {
        async loadInvoices() {

            let response = await invoiceService.list(this.$store.state.resellerId, this.paid ? 1 : 0, this.currentPage)
            this.invoices = response.data.data
            this.currentPage = response.data.current_page
            this.perPage = response.data.per_page
            this.total = response.data.total
        },
        async openPDF(invoiceId, fileName) {

            this.error = ''

            try {

                let reponse = await invoiceService.getPDF(this.$store.getters.resellerId, invoiceId)
                let content = reponse.data

                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display:none";

                let byteCharacters = atob(content);
                let byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                let byteArray = new Uint8Array(byteNumbers);
                let file = new Blob([byteArray], { type: 'application/pdf;base64' });
                let fileURL = URL.createObjectURL(file);

                a.href = fileURL;
                a.download = fileName;
                a.click();
                window.URL.revokeObjectURL(fileURL);
            }
            catch (error) {

                this.error = 'Impossible de télécharger le fichier PDF.'
            }
        },
        async downloadCSV(invoiceId, fileName) {

            let reponse = await invoiceService.getCSV(this.$store.getters.resellerId, invoiceId)
            let csvContent = reponse.data
            
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

            // Créer un lien temporaire pour le téléchargement
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);

            // Ajouter le lien au DOM, déclencher le clic, puis le supprimer
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Révoquer l'URL pour libérer la mémoire
            URL.revokeObjectURL(url);
        },
        async downloadTransferNotes(invoiceId, filename) {

            let response = await invoiceService.getTransferNotes(this.$store.getters.resellerId, invoiceId)
            let content = response.data

            let a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display:none";

            let byteCharacters = atob(content);
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            let file = new Blob([byteArray], { type: 'application/zip;base64' });
            let fileURL = URL.createObjectURL(file);

            a.href = fileURL;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(fileURL);
        },
        previousPage() {

            if (this.hasPreviousPage) {

                this.currentPage--
                this.loadInvoices()
            }
        },
        nextPage() {

            if (this.hasNextPage) {

                this.currentPage++
                this.loadInvoices()
            }
        },
        getType(type) {

            switch (type) {
                case 'fees':
                    return 'Facture de commissions'
                case 'coupons':
                    return 'Relevé des transactions'
                case 'coupons_buy':
                    return 'Facture de reprises'
                case 'cash':
                    return 'Liste de transactions'
                default:
                    return ''
            }
        },
        displayDownloadTransferNote(invoice) {

            return ['coupons', 'cash', 'coupons_buy'].includes(invoice.type)
        }
    },
    created() {

        this.loadInvoices()
    },
    watch: {
        paid() {

            this.currentPage = 1
            this.loadInvoices()
        }
    },
    computed: {
        firstIndex() {

            if (this.currentPage == undefined || this.perPage == undefined) return 0

            return (this.perPage * (this.currentPage - 1)) + 1
        },
        lastIndex() {

            if (this.currentPage == undefined || this.perPage == undefined) return 0

            return this.firstIndex + this.invoices.length - 1
        },
        hasPreviousPage() {

            return this.currentPage > 1
        },
        hasNextPage() {

            return (this.currentPage * this.perPage) < this.total
        },
        previousPageStyle() {
            
            return this.hasPreviousPage ? 'cursor:pointer;text-decoration:underline' : ''
        },
        nextPageStyle() {
            
            return this.hasNextPage ? 'cursor:pointer;text-decoration:underline' : ''
        }
    }
}
</script>
<template>
    <main-layout>
        <h3>Documents comptables</h3>
        <div class="custom-control custom-switch mt-4">
            <input type="checkbox" class="custom-control-input" v-model="paid" id="paidSwitch">
            <label v-if="paid == 1" class="custom-control-label" for="paidSwitch">Réglé</label>
            <label v-if="paid == 0" class="custom-control-label" for="paidSwitch">A régler</label>
        </div>
        <div class="alert alert-danger mt-2" v-if="error">{{ error }}</div>
        <table class="table table-bordered mt-2">
            <thead>
                <tr class="text-center">
                    <th>Numéro</th>
                    <th>Type</th>
                    <th>Date d'édition</th>
                    <th>Montant</th>
                    <th>Statut</th>
                    <th>Fichier PDF</th>
                    <th>Fichier CSV</th>
                    <th>Bons de cession</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="invoice in invoices" :key="invoice.id">
                    <td>{{ invoice.number }}</td>
                    <td>{{ getType(invoice.type) }}</td>
                    <td class="text-right">{{ invoice.created_at | date }}</td>
                    <td class="text-right">{{ invoice.amount | moneyInteger }}</td>
                    <td>{{ invoice.paid == 1 ? 'Réglé' : 'A régler' }}</td>
                    <td class="text-center">
                        <button class="btn" @click="openPDF(invoice.id, invoice.number+'.pdf')">
                            <i class="bi bi-download"></i>
                        </button>
                    </td>
                    <td class="text-center">
                        <button class="btn" @click="downloadCSV(invoice.id, invoice.number+'.csv')">
                            <i class="bi bi-download"></i>
                        </button>
                    </td>
                    <td class="text-center">
                        <button v-if="displayDownloadTransferNote(invoice)" @click="downloadTransferNotes(invoice.id, invoice.number+'_bonsdecession.zip')" class="btn">
                            <i class="bi bi-download"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div>
            <span :style="previousPageStyle" @click="previousPage()">&lt;&lt;</span> <span>{{ firstIndex }} - {{ lastIndex }} / {{ total }}</span> <span :style="nextPageStyle" @click="nextPage()">&gt;&gt;</span> 
        </div>
    </main-layout>
</template>
