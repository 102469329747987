import axios from 'axios'

export default {

    list(resellerId, paid, page) {

        var path = '/api/reseller/invoices?resellerId=' + resellerId + '&paid=' + paid + '&page=' + page

        return axios.get(path)
    },
    getPDF(resellerId, invoiceId) {

        let path = '/api/reseller/invoices/' + invoiceId + '?resellerId=' + resellerId

        return axios.get(path, {headers: {'Accept': 'application/pdf'}});
    },
    getCSV(resellerId, invoiceId) {

        let path = '/api/reseller/invoices/' + invoiceId + '?resellerId=' + resellerId

        return axios.get(path, {headers: {'Accept': 'text/csv'}});
    },
    getTransferNotes(resellerId, invoiceId) {
     
        let path = '/api/reseller/invoices/' + invoiceId + '/transferNotes' + '?resellerId=' + resellerId

        return axios.get(path)
    }
}
