import Vue from 'vue'
import './plugins/axios'
import Sentry from './plugins/sentry'
import Tracking from './plugins/tracking'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './lang/index'
import VTooltip from 'v-tooltip'
import './filters/dateTime.js'
import './filters/dateDiff.js'
import './filters/date.js'
import './filters/moneyInteger.js'

Vue.config.productionTip = false
Vue.use(Sentry)
Vue.use(VTooltip)
Vue.use(Tracking)

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
    created() {

        this.$store.dispatch('getCSRF')
    }
}).$mount('#app')
